<template>
    <b-overlay :show="isCreatingBudget || isUpdatingBudget || isDeletingBudget ">

        <div class="border p-1 mb-2" v-if="listAnnualCondominiumBudgets.length > 0">
            <div class="select-year">
                <h2>Presupuesto anual: </h2>
                <b-form-group class="mt-1">
                    <b-form-select v-model="condominiumDataEdit.selectedYear" @change="getBudgetByPeriod" :disabled="isLoadingBudgetsByPeriods">
                        <option :value="null">Seleccione un año</option>
                        <option
                            v-for="year in yearsFromBudget"
                            :key="year"
                            :value="year"
                        >
                            {{ year }}
                        </option>
                    </b-form-select>

                </b-form-group>
                <b-button
                    @click="refreshBudgets"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="isExplodingData || isLoadingAnnualFeesHousing"
                >
                    <feather-icon icon="RefreshCwIcon" style="color: white" />
                </b-button>

            </div>

            <div  v-if="budgetSelected">

                <div class="data-budget">
                    <div>
                        <p>Condominio: <span>{{ condominiumDataEdit.mainData.name }}</span></p>
                        <p v-if="budgetSelected.periodicity ">Periodicidad: <span>{{ budgetSelected.periodicity }}</span> </p>
                        <p v-else class="no-period">Periodicidad: <span>Favor de agregarle una periodicidad</span> </p>
                    </div>

                    <div>
                        <p>Cuota Condominal Anual: <span>{{ budgetSelected.showcondoFee }}</span>  </p>
                        <p>Divisa: <span>{{ budgetSelected.currency }}</span></p>

                    </div>

                    <div>
                        <b-button
                            @click="openModalAddBudget"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary mr-1"
                            :disabled="isExplodingData || isLoadingAnnualFeesHousing"
                        >
                            <feather-icon icon="PlusIcon" style="color: white" />
                        </b-button>

                        <b-dropdown
                            toggle-class="p-0"
                            no-caret
                            right
                            v-if="canShowActionsOnBudgetByPermission"
                            :disabled="isExplodingData || isLoadingAnnualFeesHousing"                            
                        >
                            <template #button-content>
                                <!-- v-if="canShowOptionsByBooleans" -->
                                <b-button variant="primary" >
                                    <feather-icon icon="MoreHorizontalIcon" style="color: white" />
                                </b-button>
                            </template>

                            <b-dropdown-item @click="openEditBudgetModal" v-if="canShowOptionBudget">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">Actualizar presupuesto</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="handlerOpenMassiveBudgetModal">
                                <feather-icon icon="DollarSignIcon" size="12" />
                                <span class="align-middle ml-50">Cobro de cuotas</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="handlerDeleteQuota" v-if="canShowOptionBudget">
                                <feather-icon icon="TrashIcon" size="12"  />
                                <span class="align-middle ml-50">Borrar presupuesto</span>
                            </b-dropdown-item>

                        </b-dropdown>


                    </div>
                </div>

                <CondominiumModalEditBudget :budget="budget" @update-data="updateData" />

                <CondominiumModalMassiveActionsBudget
                    :budget="budget"
                    :isLoadingBudgetsByPeriods="isLoadingBudgetsByMassivePeriods"
                    @update-data="updateData"
                    @update-data-after-modify="updateDataAfterModify"
                />


            </div>
        </div>

        <div v-else>
            <hr/>
            <div class="btn-add">
                <b-button
                    @click="openModalAddBudget"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                    <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
            </div>
            <div class="text-center mt-1" >

                <b-alert show variant="danger" class="p-1 ">
                    Aún no se tiene presupuestos {{  condominiumDataEdit.mainData ? 'para el condominio: ' + condominiumDataEdit.mainData.name : '' }}
                </b-alert>
            </div>
        </div>

    </b-overlay>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import Ripple from 'vue-ripple-directive'
import CondominiumModalEditBudget from "@/modules/fivesClub/components/catalogs/condominiums/CondominiumModalEditBudget"
import CondominiumModalMassiveActionsBudget from "@/modules/fivesClub/components/catalogs/condominiums/CondominiumModalMassiveActionsBudget"

import { showAlertMessage, toCurrency, sweetAlert, numberWithCommas } from '@/helpers/helpers'
import { condominalFeePeriodicity } from "@/data/fivesClub"
import { utils } from "@/modules/fivesClub/mixins/utils";
import { acl } from "@/modules/auth/mixins/acl"

export default {
	mixins: [utils, acl ],
    directives: {Ripple, },
    props:{
        isLoadingBudgetsByPeriods:{
            type: Boolean,
            required: true,
        },
        isCreatingBudget:{
            type: Boolean,
            required: true,
        },
        isUpdatingBudget:{
            type: Boolean,
            required: true,
        },
        isExplodingData:{
            type: Boolean,
            required: true, 
        },
        isLoadingAnnualFeesHousing:{
            type: Boolean,
            required: true,
        },
        isDeletingBudget:{
            type: Boolean,
            required: true,
        }
    },
    components:{
        CondominiumModalEditBudget,
        CondominiumModalMassiveActionsBudget
    },
    data() {
        return {

            currencies:[
                { id : 1, value : 'USD',  },
                { id : 2, value : 'MXN',  },
            ],
			periodicityOptions: condominalFeePeriodicity,
            budget: {
                id: null,
                periodicity: null,
                amountBudget: null,
                currency: null,
                year: null,
            },
            isLoadingBudgetsByMassivePeriods: false
        }
    },
    computed:{
        ...mapState('start', ['hotels']),
        ...mapState('fivesClubCatalogs', ['condominiumDataEdit','listAnnualCondominiumBudgets']),

        yearsFromBudget(){
			return this.listAnnualCondominiumBudgets.map( condF => condF.year )
		},
        budgetSelected(){
            const annualBudgets = JSON.parse( JSON.stringify( this.listAnnualCondominiumBudgets ) ) // rompo la referencia

            let budgetSelected = null
			const periods = { ME : 'Mensual', BI : 'Bimestral', TR : 'Trimestral', CU : 'Cuatrimestral', SE : 'Semestral', }
			const divisas = { 1 : 'USD', 2 : 'MXN',  }

            budgetSelected = annualBudgets.find( condF => condF.year === this.condominiumDataEdit.selectedYear )

            if( budgetSelected ){
                this.setSelectedBudgetCondominium( JSON.parse( JSON.stringify( budgetSelected ) ) )
                budgetSelected.showcondoFee = toCurrency(budgetSelected.amountBudget)
                budgetSelected.amountBudget = budgetSelected.amountBudget
                budgetSelected.periodicity = budgetSelected.periodicity ? periods[budgetSelected.periodicity] : null
                budgetSelected.currency = divisas[budgetSelected.currency] || null
            }
            return budgetSelected
        },
        canShowActionsOnBudgetByPermission(){
            if( this.budgetSelected ){
                const canUpdateBudget = this.can('fivesclub_housing_show_condos_update_button_action_condo_fee_edit')
                // puede borrar solo si tiene el permiso
                return canUpdateBudget
            }
            return false
        },
        canShowOptionsByBooleans(){
            if( this.budgetSelected ){

                if (this.budgetSelected.existData && this.budgetSelected.existPayment) {
                    return false
                } else {
                    return true
                }
            }
            return true
        },
        canShowOptionBudget(){
            if(this.budgetSelected){
                const existPayment = this.budgetSelected.existPayment
                // puede borrar solo si tiene el permiso y budget no tiene información
                return !existPayment
            }
            return false
        }
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['deleteCondominalFee', 'fetchCondominalBudgetByPeriods']),
        ...mapMutations('fivesClubCatalogs',['setCondominalFeesHousingByYear','setSelectedYearBudgetCondominium',
        'setSelectedBudgetCondominium','deleteBudgetInlist','setFeeHousingDetail','setCondominalBudgetByPeriods','setIsMarkedAllBudgetByPeriods','setAllMarkedBudgetByPeriods']),
        
        getBudgetByPeriod(year){

            this.setCondominalFeesHousingByYear([]) //limpio fees por viviendas
            this.setSelectedYearBudgetCondominium(null)
            this.setSelectedBudgetCondominium(null)
            // pedir periodos del presupuesto anual solo si este indica una periodicidad
            if( year ){
                const selectedBudget = JSON.parse( JSON.stringify( this.listAnnualCondominiumBudgets.find( budget => budget.year === year ) ) ) // rompo la referencia
                const payload = { idBudget: selectedBudget.id, year}

                this.setSelectedYearBudgetCondominium(year)
                // si hay budget con ese año y tenga periodicidad
                if( selectedBudget && selectedBudget.periodicity != null ){
                    this.setSelectedBudgetCondominium(selectedBudget)
                    this.$emit('get-condominal-budget-by-year', payload)
                }
            }
        },
        async handlerOpenMassiveBudgetModal(){
            this.isLoadingBudgetsByMassivePeriods = true
            this.setCondominalBudgetByPeriods([])
            this.setBudgetSelected()
            this.setIsMarkedAllBudgetByPeriods(false)
            this.setAllMarkedBudgetByPeriods({isMarked: false})
            this.$root.$emit("bv::show::modal", 'modal-massive-budget')

            const periods = await this.fetchCondominalBudgetByPeriods({ idBudget: this.condominiumDataEdit.selectedBudget.id, Periodicity: this.condominiumDataEdit.selectedBudget.periodicity, idHousing: null })
            //uso solos periodos cuyo balance es mayor a cero
            let validPeriods = []
            periods.forEach( period => {                
                if( period.balanceAmount > 0 ){
                    period.isMarked = false
                    validPeriods.push(period)
                }
            })
            this.setCondominalBudgetByPeriods(validPeriods)
            this.isLoadingBudgetsByMassivePeriods = false
        },
        openModalAddBudget(){
            this.$emit('open-modal-add-budget')
        },
        openEditBudgetModal(){
            this.setBudgetSelected()
            this.$root.$emit("bv::show::modal", 'modal-edit-budget')
        },
        setBudgetSelected(){
            const budget = JSON.parse( JSON.stringify( this.budgetSelected ) ) // rompo la referencia

            const periods = { Mensual: 'ME', Bimestral: 'BI',Trimestral: 'TR', Cuatrimestral: 'CU', Semestral: 'SE', }
            const divisas = { USD : 1, MXN : 2,  }

            this.budget.id = budget.id
            this.budget.periodicity = budget.periodicity ? periods[budget.periodicity] : null
            this.budget.amountBudget = budget.amountBudget
            this.budget.currency = budget.currency ? divisas[budget.currency] : null
            this.budget.year = budget.year
        },
        formatAmount(amount){
			return numberWithCommas(amount)
		},
        updateData(year){
            const payload = { idBudget: this.condominiumDataEdit.selectedBudget.id, year}            
            this.$emit('update-data', payload ) // Actuualizo los datos de la vista
            // this.$emit('get-annuals-condominal-budgets')
			// this.$emit('get-condominal-budget-by-year', payload) // pido los	fees year por vivieda y periodos
        },
        async refreshBudgets(){
            this.setCondominalFeesHousingByYear([])
            this.setFeeHousingDetail(null)
            this.$emit('get-annuals-condominal-budgets')
            this.setSelectedYearBudgetCondominium(  this.budgetSelected.year )
            this.$emit('get-fees-housing-by-year', this.budgetSelected.year )
        },
        async handlerDeleteQuota(){
            let processSuccess = false
            this.setBudgetSelected()
            const currentYear = new Date().getFullYear() // año actual

            const canDeleteByYear = currentYear < this.budget.year

            // si año actual no es menor al año del presupuesto seleccionado
            if( !canDeleteByYear ){
                return showAlertMessage('No se puede borrar', 'InfoIcon', 'Solo puede borrar cuotas de años mayores al actual', 'danger', 4000, 'bottom-right' )
            }

            const isConfirmed = await this.confirmDelete()
            const budget = JSON.parse( JSON.stringify( this.budget  ))

            const payloadDelete = { id: budget.id }

            if( isConfirmed ){
                this.$emit('set-is-deleting-budget', true)

                this.setCondominalFeesHousingByYear([])
                this.setFeeHousingDetail(null)

                const { status, message } = await this.deleteCondominalFee(payloadDelete)

                processSuccess = status

                if (status) {
                    showAlertMessage("Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right" )
                    // elimino el budget del state
                    await this.deleteBudgetInlist(this.budget.id)

                }
            }
            // si fue exitoso
            if( processSuccess ){
                this.updateDataAfterDelete() // actualizo lista de presupuestos
            }
            this.$emit('set-is-deleting-budget', false)

        },
        updateDataAfterModify(){
            this.$emit('get-info-tab') 
        },
        updateDataAfterDelete(){
            const currentYear = new Date().getFullYear()            
            const totalBudgets = this.listAnnualCondominiumBudgets.length
            const defaultYear = totalBudgets > 0 ? this.listAnnualCondominiumBudgets[ totalBudgets - 1 ].year : currentYear            
            this.setSelectedYearBudgetCondominium( defaultYear ) // seteo el nuevo año seleccionado
            if( totalBudgets > 0 ){
                this.$emit('get-info-tab') // pido info si se que hay budgets
            }            
        },
        async confirmDelete() {
            const { isConfirmed } = await this.$swal.fire({
                title: `Borrar presupuesto: ${ this.budget.year }`,
                text: `Las cuotas por vivienda de este año también se eliminarán. ¿Seguro que desea eliminar esta cuota?`,
                showDenyButton: true,
                confirmButtonText: `Sí, seguro`,
                denyButtonText: `Cancel`,
                customClass: {
                    confirmButton: 'mr-1',
                }
            })
            return isConfirmed
        }
    }
}
</script>

<style scoped>
.select-year{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 1.5rem;
}

.select-year h2{
    font-size: 1.2rem;
    color: black;
}

.data-budget{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.data-budget p{
    font-weight: bold;
    color: black;
    margin: 0;
}

.data-budget p span{
    font-weight: normal;
}

.no-period span {
    color: red;
}

.btn-add{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


</style>