import { render, staticRenderFns } from "./CondominiumBudget.vue?vue&type=template&id=f5b33a40&scoped=true&"
import script from "./CondominiumBudget.vue?vue&type=script&lang=js&"
export * from "./CondominiumBudget.vue?vue&type=script&lang=js&"
import style0 from "./CondominiumBudget.vue?vue&type=style&index=0&id=f5b33a40&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5b33a40",
  null
  
)

export default component.exports