<template>
    <div>
        <b-overlay :show="isLoadingAnnualFeesHousing" class="mt-1">

            <div class="filters-t mb-1" v-show="condominalFeesHousingByYear.length">

                <div class="btn-filter-filter-actions">
                    <b>Total de Registros: </b> <b-badge variant="success">  {{ condominalFeesHousingByYear.length }} </b-badge>
                    <b-button
                        size="sm"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="showAllHousing"
                        :disabled="!showBtnViewAll"
                    >
                        <feather-icon icon="Maximize2Icon" style="color: white" />  Ver todos
                    </b-button>


                    <b-dropdown
                        toggle-class="p-0"
                        no-caret
                        right
                    >
                        <template #button-content>
                            <!-- v-if="canShowOptionsByBooleans" -->
                            <b-button
                                size="sm"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                            >
                                <feather-icon icon="MoreHorizontalIcon" style="color: white" />
                            </b-button>
                        </template>
                        <b-dropdown-item @click="getBudgetReport" :disabled="isLoadingExcelReport">
                            <b-spinner v-if="isLoadingExcelReport" small/>
                            <feather-icon v-else icon="FileTextIcon" size="12"  />
                            <span class="align-middle ml-50">Reporte Anual</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="getCondoFeeAnualDetailReport" :disabled="condominalHousingFeesByYear.length === 0 || isLoadingExcelDetailsReport">
                            <b-spinner v-if="isLoadingExcelDetailsReport" small/>
                            <feather-icon v-else icon="FileTextIcon" size="12"  />
                            <span class="align-middle ml-50">Reporte Transaccional</span>
                        </b-dropdown-item>
                    </b-dropdown>

                </div>
                <div class="actions-filter" >


                    <b-form-group label="Registros por página" v-show="condominalFeesHousingByYear.length > 0">
                        <v-select
                            v-model="porPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            @input="changePerPage"

                        />
                    </b-form-group>

                    <b-form-group label="Buscar en tabla" v-show="condominalFeesHousingByYear.length > 0" >
                        <b-form-input
                            class="input-search-t"
                            type="text"
                            placeholder="Buscar por vivienda, propietario"
                            v-model="filter.queryCondominalFeesHousingByYear"
                            @input="setFilterSearchHousing"
                        >
                        </b-form-input>
                    </b-form-group>

                </div>

            </div>

            
            <b-table
                :fields="fieldsFees"
                :items="condominalFeesByPeriodsData"
                responsive 
                show-empty
                empty-text="No hay registros para mostrar"
                stacked="md" 
                :thead-class="!condominalHousingFeesByYear.length ? 'd-none' :''"

            >
                <template #cell(housingnumber)="row">
                    <b-link class="edit-h capitalize-text"  @click="showDetails(row.item)">
                        <span> {{ row.item.housingnumber }}  </span>
                    </b-link>
                </template>

                <template #cell(actions)="row">
                    <div class="dropdown-cell">
                        <b-dropdown
                            toggle-class="p-0 custom-dropdown-item"
                            no-caret
                            right


                        >
                            <template #button-content>
                                <b-button variant="primary" size="sm">
                                    <feather-icon icon="MoreHorizontalIcon" style="color: white" />
                                </b-button>
                            </template>
                            <!-- <b-dropdown-item @click="openModalFeeQuota(row.item)" v-if="row.item.paymenttypename !== 'Rendimiento' "> -->
                            <b-dropdown-item @click="openModalFeeQuota(row.item)">
                                <feather-icon icon="DollarSignIcon" />
                                <span class="align-middle custom-dropdown-item ml-50"> Pago de cuota </span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="openModalChargeQuota(row.item)">
                                <feather-icon icon="UploadIcon" size="12" />
                                <span class="align-middle custom-dropdown-item ml-50"> Cargar cuotas </span>
                            </b-dropdown-item>
                            <b-dropdown-item :href="`${authURL}/FivesClub/accountstatement/${row.item.housingid}/${condominiumDataEdit.selectedBudget ? condominiumDataEdit.selectedBudget.year : ''}`" target="_blank" rel="noopener noreferrer" >
                                <feather-icon icon="FileTextIcon" size="12" />
                                <span class="align-middle custom-dropdown-item ml-50">Estado de cuenta </span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>

                </template>

            </b-table>
        


            <PaginationTable
                v-show="condominalHousingFeesByYear.length > 10"
                :currentPage="currentPage"
                :totalRows="condominalHousingFeesByYear.length"
                :perPage="porPage"
                @change-page="changePage"
            />

            <CondominiumHousingModalQuotaCharge                
                :selectedHousing="selectedHousing"
                :periodsHousing="periodsHousing"
                :isLoadingPeriodsData="isLoadingPeriodsData"
                @set-marked="setMarkedRadio"
                @set-is-loading-annual-fees-housing-from-modal="setIisLoadingAnnualFeesHousing"                
                @get-fees-housing-by-year="getFeesHousingByYear"
                @update-housing-and-detail="updateHousingRow"
                
            />

            <CondominiumHousingModalPaymentQuota                
                :selectedHousing="selectedHousing"
                :periodsHousing="periodsHousing"
                :paymentTypes="paymentTypes"
                :isLoadingPeriodsData="isLoadingPeriodsData"
                @set-marked="setMarked"
                @set-is-loading-annual-fees-housing-from-modal="setIisLoadingAnnualFeesHousing"                
                @get-fees-housing-by-year="getFeesHousingByYear"
                @update-housing-and-detail="updateHousingRow"                
            />

        </b-overlay>

        <b-overlay :show="isLoadingAnnualFeesHousingDetail" class="mt-2">
            <div style="overflow-x: auto;">            
                <b-table
                    responsive 
                    v-if="detailsFeeHousing"
                    :fields="fieldsDetailFee"
                    :items="feesHousingDetails"
                    class="mb-0 "
                    show-empty
                    empty-text="No hay detalles de registro a mostrar"
                    :thead-class="!feesHousingDetails.length ? 'd-none' :''"
                    :tbody-tr-class="rowClass"
                >
                </b-table>
            </div>
        </b-overlay>

    </div>

</template>

<script>

import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import { toCurrency, formatDateSpecific, showAlertMessage } from '@/helpers/helpers'
import PaginationTable from '@/modules/fivesClub/components/catalogs/housing/PaginationTable'
import CondominiumHousingModalQuotaCharge from '@/modules/fivesClub/components/catalogs/condominiums/CondominiumHousingModalQuotaCharge'
import CondominiumHousingModalPaymentQuota from '@/modules/fivesClub/components/catalogs/condominiums/CondominiumHousingModalPaymentQuota'
import Ripple from 'vue-ripple-directive'



import vSelect from 'vue-select'

export default {
    directives: {Ripple, },

    props:{     
        isLoadingAnnualFeesHousing:{
            type: Boolean,
            required: true,
        },
        paymentTypes:{
            type: Array,
            required: true,
        },
        isLoadingExcelDetailsReport:{
            type: Boolean,
            required:true
        },
        isLoadingExcelReport:{
            type: Boolean,
            required:true
        }
    },
    components: { vSelect, CondominiumHousingModalPaymentQuota, CondominiumHousingModalQuotaCharge, PaginationTable },

    data(){
        return {
            porPage: 10,
            // perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 15, 20, 40, 60, 80, 100],
            fieldsFees: [
                { key:'housingnumber', label: 'Vivienda' },
                { key:'owner', label: 'Propietario' },
                { key:'code', label: 'Contrato' },
                { key:'membershipcode', label: 'Membresía' },
                { key:'paymenttypename', label:'Tipo de pago' },
                { key:'amountAnual', label:'Total anual', class:'text-nowrap',formatter: (value, key, item) => {return item?.totals?.amountAnual != null ? toCurrency(parseFloat(item?.totals?.amountAnual)) : 'N/A'} },
                { key:'currentBalance', label:'Saldo Actual', class:'text-nowrap',formatter: (value, key, item) => {return item?.totals?.currentBalance != null ? toCurrency(parseFloat(item?.totals?.currentBalance)) : 'N/A'} },

                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
            fieldsDetailFee:[
                { key:'operationdate', label:'Fecha', class:'text-nowrap' },
                { key:'subtypeName', label:'Concepto' },
                { key:'period', label:'Periodo', formatter: value => {return value?.periodName} },
                { key:'typeName', label:'Tipo' },
                { key:'quantity', label:'Cantidad', class:'text-nowrap', formatter: value => {return toCurrency(parseFloat(value))} },
                { key:'paymentmethod', label:'Forma de Pago' },                 
                { key:'userName', label:'Usuario' },                

            ],
            isLoadingAnnualFeesHousingDetail: false,
            isLoadingPeriodsData: false,
            authURL: process.env.VUE_APP_IMG_SRC_API,
            selectedHousing: null,
            periodsHousing: [],

        }
    },
    computed:{
        ...mapState('start', ['hotels']),
        ...mapState('fivesClubCatalogs', ['condominalFeesHousingByYear','detailsFeeHousing','condominalBudgetByPeriods','filter','condominiumDataEdit']),
        ...mapState('auth', ['user']),
        ...mapState("groups", ["roomsTypes"]),
        ...mapGetters('fivesClubCatalogs',['condominalHousingFeesByYear']),
        condominalFeesByPeriodsData(){
            if (this.condominalHousingFeesByYear?.length){
                return this.condominalHousingFeesByYear.slice( (this.currentPage - 1) * this.porPage, this.currentPage * this.porPage )
            }
        },
        feesHousingDetails(){
            let details = []
            if( this.detailsFeeHousing ){
                details = this.detailsFeeHousing.details
            }
            return details
        },
        showBtnViewAll(){
            const totalHousing = this.condominalHousingFeesByYear.length
            return totalHousing === 1 && this.condominalHousingFeesByYear[0].isShowingDetails
        }
    },
    methods:{
        ...mapActions('fivesClubCatalogs',['fetchCondoFeeHousingDetail','fetchFeeHousingDetail','fetchCondominalBudgetByPeriods','fetchCondominalBudgetFeesByYear']),
        ...mapMutations('fivesClubCatalogs',['setFeeHousingDetail','setIsShowingDetailsHousing','setFilterSearchCondominalFeesHousingByYear','setShowAllFeesHousingByYear','setCondominalFeesHousingByYear']),
        formatMoney(data){
            const {value, item} = data
            return toCurrency(value, item?.currency?.code)
        },
        setFilterSearchHousing(text){
            const formattedText = text.trim() //elimino espacios vacios al principio/fin de la cadena
            if( formattedText.length > 0 ){
                const finalText = !isNaN(formattedText) ? formattedText : formattedText.toLowerCase()
                this.setFilterSearchCondominalFeesHousingByYear(finalText)
                this.setFeeHousingDetail(null)
            }
        },
        showAllHousing(){
            this.setShowAllFeesHousingByYear()
            this.setFeeHousingDetail(null)
        },
        setIisLoadingAnnualFeesHousing(bool){
            this.$emit('set-is-loading-annual-fees-housing', bool)
        },
        setIsLoadingAnnualFeesHousingDetail(bool){
            this.isLoadingAnnualFeesHousingDetail = bool
        },
        getBudgetReport(){
            this.$emit('get-annuals-condominal-budgets-report')
        },
        getCondoFeeAnualDetailReport(){
            this.$emit('get-condominal-fees-details-report')
        },
        formatDate(data){
            return formatDateSpecific({date: data, format:'M', toFormat:'MMMM'})
        },
        changePerPage( perPage ){
            this.perPage = perPage
        },
        changePage(page){
            this.currentPage = page
        },
        setMarked(item){

            const { idPeriod } = item           
            const idx = this.periodsHousing.findIndex( b => b.idPeriod === idPeriod )
            this.periodsHousing[idx].isMarked = true
        },
        setMarkedRadio(item){

            const { idPeriod } = item

            this.periodsHousing.forEach(period => {
                period.isMarked = false
            })

            const idx = this.periodsHousing.findIndex( b => b.idPeriod === idPeriod )
            this.periodsHousing[idx].isMarked = true
        },
        // Pago de cuota
        async openModalFeeQuota(item){
            this.$root.$emit("bv::show::modal", 'modal-quotas-condo-housing-payment-quota');            
            this.selectedHousing = JSON.parse(JSON.stringify(item))            
            
        },
        // Cargar cuota
        async openModalChargeQuota(item){
            this.$root.$emit("bv::show::modal", 'modal-quotas-condo-housing-charge')            
            this.selectedHousing = JSON.parse(JSON.stringify(item))                         
        },
        updateHousingAfter(){

        },
        async showDetails(item){
            // console.log(item.isShowingDetails)
            // console.log(item)

            const isShowinDetail = !item.isShowingDetails
            this.setIsShowingDetailsHousing({ id: item.id, bool: isShowinDetail})
            this.setFeeHousingDetail(null)
            this.isLoadingAnnualFeesHousingDetail = true
            const payload = { idHousing: item.housingid, idBudget: this.condominiumDataEdit.selectedBudget.id }

            //pedir solo cuando se esta viendo detalles
            if( isShowinDetail ){
                const { status, data, message } = await this.fetchFeeHousingDetail( payload )
                if( status ){
                    this.setFeeHousingDetail(data)
                }
                if( !status ){
                    showAlertMessage("Sucedió un error", "InfoIcon", `Ocurrió un error: ${message}`, "danger", 4000, "bottom-right" )                    
                }
            }
            this.isLoadingAnnualFeesHousingDetail = false
        },
        getFeesHousingByYear(year){
            this.$emit('get-fees-housing-by-year', year)
        },
        async updateHousingRow( housing ){  
            this.setIsLoadingAnnualFeesHousingDetail(true)          
            const restHousing = JSON.parse(JSON.stringify( this.condominalFeesHousingByYear.filter( h => h.id !== housing.id)) )   
            this.setCondominalFeesHousingByYear([])            
            const housings = await this.fetchCondominalBudgetFeesByYear({idHousing: housing.housingid, idBudget: this.condominiumDataEdit.selectedBudget.id})
            if( housings.length > 0 ){
                housings[0].isShowingDetails = false
                const stateUpdated = [ housings[0], ...restHousing]   // actualizo el state con los nuevos datos del housing             
                this.setCondominalFeesHousingByYear(stateUpdated)
                await this.showDetails(housings[0])
            }    
            this.setIsLoadingAnnualFeesHousingDetail(false)          

        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if ([1, 6].includes(item.subtype)) return 'font-weight-bolder'
        }

    }
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.capitalize-text{
  text-transform: capitalize;
}
.actions-filter{
    display: flex;
    flex-direction: column;
}

.btn-filter-filter-actions{
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
}

.input-search-t{
    width: 100%;
}

.edit-h{
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

@media (min-width:640px) {
    .actions-filter{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
    }

    .input-search-t{
        min-width: 320px;
        max-width: 400px;
    }

}

@media (min-width:768px) {
    .actions-filter{
        display: flex;
        flex-direction: row;
        justify-content: unset;
        gap: 1rem;
    }

}

@media (min-width:1024px) {
    .filters-t{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

    .actions-filter{
        order: 1;
    }

    .btn-filter-filter-actions{
        order: 2;
    }

    .input-search-t{
        min-width: 450px;
        /* max-width: 400px;         */
    }

}

/* @media (max-width: 767px) {
    .table-responsive .dropdown-menu {
        position: static !important;
    }
}
@media (min-width: 768px) {
    .table-responsive {
        overflow: visible;
    }
} */
/*
.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
    position: static;
}
*/

.dropdown-cell {
  position: relative;
}

.dropdown-cell .dropdown-menu {
  position: absolute;
  z-index: 9999;
}
</style>